import styled from 'styled-components';

export const Styles = styled.aside`
  display: grid;
  font-family: var(--serif);
  font-size: var(--h5);
  grid-gap: var(--spacing);
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;

  @media (min-width: 768px) {
    grid-gap: calc(var(--spacing) * 2);
  }

  @media (min-width: 1200px) {
    grid-gap: calc(var(--spacing) * 3);
  }

  a {
    color: var(--cPrimary);
    text-decoration: none;
  }

  .btns {
    display: flex;
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    justify-content: space-between;
  }

  .numbers {
    grid-column: 1 / 3;
    grid-row: 2 / 3;

    a,
    span {
      padding-right: var(--spacing);
    }

    span {
      color: var(--cTertiary);
    }
  }
`;
