import { Styles } from './styled';
import { Props } from './types';

export function Button({
  children,
  type,
}: Props) {
  return (
    <Styles type={type}>
      {children}
    </Styles>
  )
}
