import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { BlogItem } from '../../components/BlogItem';
import { Button } from '../../components/Button';
import { SEO } from '../../components/SEO';
import { getJournalPath } from '../../constants/path';

import { Styles } from './styled';
import { Props } from './types';

export function Journal({
  data: {
    allMdx: {
      edges,
    },
  },
  pageContext: {
    currentPage,
    nextPagePath,
    pages,
    previousPagePath,
  },
}: Props) {
  const { t } = useTranslation();

  return (
    <>
      <SEO
        description={t('template.journal.seo.description')}
        title={t('template.journal.seo.title')}
      />
      <h1>
        {t('template.journal.title')}
      </h1>
      <p>
        {t('template.journal.description')}
      </p>
      {edges.map(({ node }, index) => <BlogItem key={`blogItem${index}`} node={node} />)}
      {pages > 1 && (
        <Styles>
          <div className="btns">
            {currentPage > 1 && (
              <Link rel="prev" to={previousPagePath}>
                <Button>
                  {t('template.journal.button.previous')}
                </Button>
              </Link>
            )}
            {currentPage !== pages && (
              <Link rel="next" to={nextPagePath}>
                <Button>
                  {t('template.journal.button.next')}
                </Button>
              </Link>
            )}
          </div>
          <div className="numbers">
            {Array.from({ length: pages }, (_, i) => {
              const page = i + 1;
              return page === currentPage
                ? (
                  <span key={`paginationNumber${page}`}>
                    {page}
                  </span>
                )
                : (
                  <Link
                    key={`paginationNumber${page}`}
                    to={getJournalPath(page)}
                  >
                    {page}
                  </Link>
                );
            })}
          </div>
        </Styles>
      )}
    </>
  )
}
