import styled from 'styled-components';

export const Styles = styled.button`
  background-color: transparent;
  border: none;
  color: var(--cSecondary);
  display: flex;
  font-family: var(--serif);
  font-size: var(--h5);
  margin: 0;
  padding: 0;
  position: relative;
  text-decoration: none;

  &:after {
    background-color: var(--cSecondary);
    bottom: -.25rem;
    content: "";
    display: block;
    height: .1rem;
    left: 0;
    opacity: 1;
    position: absolute;
    transition: opacity var(--transSlow);
    width: 100%;
  }

  &:hover,
  &:focus {
    cursor: pointer;

    &:after {
      opacity: .15;
    }
  }

  &:visited {
    text-decoration: none;
  }
`;
